import React from 'react';
import { ReactComponent as DashIcon } from './Icons/Dash.svg';
import styled, { useTheme } from 'styled-components';
import { IGrowerResponse } from '../../../logic/Models/Responses/GrowerResponse';
import { StyledRowCell } from '../../../components/Table/TableRow';
import { BoolDictionary, UISort } from '../../../logic/store/UI/UISlice';
import { SortType } from '../../../components/Table/TableHeader';
import { StyledScrollableTableRows } from '../../../components/Table/TableContainer';
import { TableRows } from '../../../components/Table/TableRows';
import { IGrowerWithContractDetails } from '../../../logic/Models/Responses/EnogenContractResponse';

export const StyledNameCell = styled.div`
    display: block;
    justify-items: start;
    grid-auto-flow: column;
    align-items: center;
`;

export const StyledDashIcon = styled(DashIcon)`
	justify-self: center;
`;
export const StyledDashboardScrollableTableRows = styled(StyledScrollableTableRows)`
	padding-top: 1.5%;
	height: 90%;
`;

export const StyledDashboardTableRows = styled(TableRows)`
	height: 100%;
`;
export const headersToKeys: { [key: string]: keyof IGrowerResponse | undefined; } =
{
	'Name / Segment': 'Name',
	'Accepted Acres / Mapped Acres': 'TotalAcresFarmed'
};
type GrowerResponseKeys = keyof IGrowerResponse;
export const keysToHeaders: Partial<{ [key in GrowerResponseKeys]: string }> =
{
	'Name': 'Name / Segment',
	'TotalAcresFarmed': 'Accepted Acres / Mapped Acres'
};
export interface IDashboardTableProps
{
	DashboardSort: UISort<IGrowerResponse>;
	DashboardTableExpanded: BoolDictionary;
	cropYear: number;
	currentCropYear: number;
	growers: IGrowerResponse[];
	userIsGrower: boolean;
	onSort: (sort: SortType, header: string) => void;
	SetExpanded: (idEntry: BoolDictionary) => void;
	SetSelectedGrowerId: (growerId: string) => void;
	ClearSelectedProductPlanId: () => void;	
}

const nameColumnWidth = 23;
const expanderArrowWidth = 5;
export const getHeaderSpacing = (headers: string[] = [], minWidths: number[] = []): string | undefined =>
{
	const length = headers.length;
	if (!length || length === 1) return;
	const individualSpacing = (100 - (nameColumnWidth + expanderArrowWidth)) / (length - 1);
	const spreadColumns = headers.reduce((acc, item, index) =>
	{
		if (index)
		{
			if(minWidths.length > index && minWidths[index] > 0)
			{
				acc += `${minWidths[index]}px `;
			}
			else
			{
				acc += `${individualSpacing}fr `;
			}
		}
		return acc;
	}, '');
	return `${nameColumnWidth}fr ${spreadColumns}${expanderArrowWidth}fr`;
};

export const getExpandedSpacing = (headers: string[] = []): string | undefined =>
{
	const length = headers.length;
	const individualSpacing = length - 1 > 0 ? (100 - (nameColumnWidth + expanderArrowWidth)) / (length - 1) : 15;
	const spreadColumns = headers.reduce((acc, item, index) =>
	{
		if (index && index !== 1)
		{
			acc += `${individualSpacing}fr `;
		}
		return acc;
	}, '');
	return `${nameColumnWidth + individualSpacing}fr ${spreadColumns}`;
};

interface IAcresProps extends IGrowerResponse,IGrowerWithContractDetails
{
	cropYear: number;
}
export const Acres = React.memo<IAcresProps>(
	function Acres(props: IAcresProps)
	{
		const { TotalAcresFarmed, NewContractId, ContractedAcres, AssignedAcres, SeasonData, cropYear } = props;
		const theme = useTheme();
		const currentSeason = SeasonData.find((season) => season.SeasonYear === cropYear);
		
		const displayedAcres = () =>
		{
			// if we have an Enogen Contract Id, return the Enogen Contract values instead
			if (NewContractId)
			{
				return `${AssignedAcres}/${ContractedAcres}`;
			}
			// Otherwise, not an Enogen Contract so show acres as normal
			else if (TotalAcresFarmed)
			{
				return `${currentSeason && currentSeason.AcceptedGamePlanAcres ? Math.ceil(currentSeason.AcceptedGamePlanAcres) : undefined || 0}/${Math.ceil(TotalAcresFarmed)}`;
			}
			else
			{
				return '0';
			}
		};

		return(
			<StyledRowCell
				className='Acres'
				style={{ margin: 'auto', width: '80%', color: TotalAcresFarmed ? 'inherit' : theme.colors.lightGrey, borderBottom: `1px solid ${theme.colors.darkGrey}` }}>
				{displayedAcres()}
			</StyledRowCell>
		);
	}
);
