import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { HybridProductRow } from './HybridProductRow';
import { UserPermission } from '../../../../logic/Models/Responses/UserPermissions';
import { hasEffectivePermission } from '../../../../logic/store/User/AuthSlice';
import { SeriesProductRow } from './SeriesProductRow';
import { ICropBrandAssociation } from '../CropBrandAssociation';
import { AppDispatch, RootState } from '../../../../logic/store/Store';
import
{
	addProductLineToEditingState,
	addSeriesToEditingState,
	IPlanEditState,
	IRecommendationOptions,
	removeProductLineFromEditingState,
	removeSeriesFromEditingState,
	updateRecommendationOptions
} from '../../../../logic/store/Plans/ProductGamePlanSlice';
import { makeDispatch } from '../../../../logic/Utility/Utils';
import { updateProduct, updateYieldTarget } from '../../../../logic/store/Plans/ProductGamePlanUpdateActions';
import { ConnectedProductSummaryRow } from './ProductSummaryRow';
import { Collapse, Tooltip } from 'antd';
import { IOrderLine, IOrderResponse } from '../../../../logic/Models/Responses/OrderResponse';
import { IHybridResponse } from '../../../../logic/Models/Responses/SeedResponse';
import { ConnectedOrderHybridRow } from './OrderHybridRow';
import { dynamicSort } from '../../../../logic/Utility/Utils';
import { CropConfig, soyId } from '../../../../logic/store/Seeds/CropsSlice';
import { IncrementNumberInput } from '../../../../components/IncrementNumberInput/IncrementNumberInput';
import { Button } from '../../../../components/Button/Button';
import _ from 'lodash';
import { StyledModal } from '../../../../components/StyledModal/StyledModal';
import { EditableInputDisplay } from '../../../../components/EditableInputDisplay/EditableInputDisplay';
import { getFieldlessSeedAllocations, IFieldlessSeedRankingsRequest } from '../../../../logic/store/Plans/ProductGamePlanActions';
import { IFieldlessAllocationResponse } from '../../../../logic/Models/Responses/FieldlessSeedRankingResponse';
import { IGrowerResponse } from '../../../../logic/Models/Responses/GrowerResponse';
import { AgronomicsModal } from '../AgronomicsModal/AgronomicsModal';
import { themeGet } from '@styled-system/theme-get';
import styled, { useTheme } from 'styled-components';

const Pill = styled.div`
	background-color: #F0F0F0;
	color: ${themeGet('colors.mediumGrey')};
	border-radius: 14px;
	padding: 4px 16px 4px 16px;
	margin-right: 5px;
`;

const StyledCollapse = styled(Collapse)`
	.ant-collapse-content-box {
		height: 100%;
	}
	.ant-collapse-content {
		height: 100%;
	}
`;

const StyledCollapsePanel = styled(Collapse.Panel)`
	height: 100%;
`;

export interface ITrait
{
	TraitName: string;
	TraitId: string;
	Selected: boolean;
}

interface IAppliedFilter
{
	RM: string;
	Irrigation: boolean;
	Fungicide: boolean;
	Traits: string;
}

interface IProductPlanEditorProps
{
	editingState: IPlanEditState;
	hybrids: IHybridResponse[];
	lastYearGrowerOrders: IOrderResponse[];
	planId: string;
	recommendation: ICropBrandAssociation;
	selectedGrower: IGrowerResponse;
	getFilteredRankings: (filterApplied?: boolean) => void;
	getRates: () => void;
	resetSeedFilters: () => void;
}

const mapStateToProps = (state: RootState) => ({
	AllowPricing: hasEffectivePermission(state, UserPermission.CanSeePricing),
	AllowProductPlanRecommendation: hasEffectivePermission(state, UserPermission.CanSeeProductPlanRecommendations),
	CustomTreatments: state.customTreatments.treatments,
	FilteredRankings: state.productGamePlan.fieldlessRankings,
	HybridLookup: state.productGamePlan.hybridLookup,
	IsFieldlessLoading: state.productGamePlan.isFieldlessLoading,
	IsLoadingPlantingRates: state.plantingRate.isLoadingPlantingRates,
	IsPlantingRateError: state.plantingRate.isError,
	PlantingRateError: state.plantingRate.error,
	PlantingRates: state.plantingRate.rates,
	SeriesLookup: state.productGamePlan.seriesLookup,
	Treatments: state.productGamePlan.treatments,
	UserDefaultTreatment: state.customTreatments.userDefaultTreatments,
	UserIsGrower: hasEffectivePermission(state, UserPermission.IsGrower, true),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
	AddNewItem: makeDispatch(dispatch, addProductLineToEditingState),
	AddNewSeries: makeDispatch(dispatch, addSeriesToEditingState),
	GetFieldlessRecommendations: makeDispatch(dispatch, getFieldlessSeedAllocations),
	OnUpdate: makeDispatch(dispatch, updateProduct),
	OnUpdateYield: makeDispatch(dispatch, updateYieldTarget),
	RemoveItem: makeDispatch(dispatch, removeProductLineFromEditingState),
	RemoveSeries: makeDispatch(dispatch, removeSeriesFromEditingState),
	UpdateFieldlessOptions: makeDispatch(dispatch, updateRecommendationOptions),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/**
 * The main editor section for modifying the plan and its details.
 */
export function PlanProductEditor(props: IProductPlanEditorProps & PropsFromRedux)
{
	const {
		editingState,
		hybrids,
		lastYearGrowerOrders,
		planId,
		recommendation,
		selectedGrower,
		getFilteredRankings,
		getRates,
		resetSeedFilters,
		AllowPricing,
		AllowProductPlanRecommendation,
		CustomTreatments,
		FilteredRankings,
		HybridLookup,
		IsFieldlessLoading,
		IsLoadingPlantingRates,
		IsPlantingRateError,
		PlantingRateError,
		PlantingRates,
		SeriesLookup,
		Treatments,
		UserDefaultTreatment,
		UserIsGrower,
		AddNewItem,
		AddNewSeries,
		GetFieldlessRecommendations,
		OnUpdate,
		OnUpdateYield,
		RemoveItem,
		RemoveSeries,
		UpdateFieldlessOptions,
	} = props;

	const theme = useTheme();

	const { cropId, cropType, cropName, brandApplication } = recommendation;
	const [isShowSeedsModalOpen, setIsShowSeedsModalOpen] = useState(false);
	const [isAgronomicInputsModalOpen, setIsAgronomicInputsModalOpen] = useState(false);
	const [uniqueTraits, setUniqueTraits] = useState<ITrait[]>([]);
	const [defaultMinMaxRm, setDefaultMinMaxRm] = useState<[number,number]>([0,200]);
	const [appliedFilters, setAppliedFilters] = useState<{[key: string]: IAppliedFilter}>({});

	const defaultTreatmentName = UserDefaultTreatment && UserDefaultTreatment[cropId]?.Treatment;

	const isSoy = cropId === soyId;

	const seriesInThisRecommendation = Object.values(editingState.series)
		.filter(series => series.cropId === cropId && series.cropType === cropType)
		.map(series => series.series);
	const hybridsInThisRecommendation = Object.values(editingState.products)
		.filter(product => !!seriesInThisRecommendation.find(s => s.seriesId === product.seriesId))
		.map(product => product.product);
	const lastYearGrowerOrderSeeds = lastYearGrowerOrders?.flatMap(order => order.Seeds).filter(seed =>
		cropName.toLowerCase().includes(seed.CropFamily.toLowerCase()) && 
			(cropType.toLowerCase().includes(seed.Brand.toLowerCase()) 
				// Special case enogen as the brand in CropEdge seems to be 'EG' 
				|| seed.Brand.toLowerCase() == 'eg' && cropType.toLowerCase().includes('enogen'))
		&& seed.HybridId && seed.SeriesId)
		?? [];
	const availableHybridIds = hybrids.map(h => h.Id);
	const availableSeeds: IOrderLine[] = [];
	const unavailableSeeds: IOrderLine[] = [];
	const allSeeds: IOrderLine[] = [];
	lastYearGrowerOrderSeeds.forEach((seed: IOrderLine) =>
	{
		const searchForHybridId = seed.CurrentEquivalent ? seed.CurrentEquivalent.HybridId : seed.HybridId;
		// Look up the current equivalent values and combine quantities in case there were multiple orders
		if (availableHybridIds.includes(searchForHybridId))
		{
			const availIndex = availableSeeds.findIndex(avail => avail.CurrentEquivalent ? 
				avail.CurrentEquivalent.HybridId === searchForHybridId : avail.HybridId === searchForHybridId);
			if (availIndex === -1)
			{
				availableSeeds.push(_.cloneDeep(seed));
			}
			else if (availIndex >= 0)
			{
				availableSeeds[availIndex].Quantity += seed.Quantity;
			}
		}
		else
		{
			const unavailIndex = unavailableSeeds.findIndex(unavail => unavail.CurrentEquivalent ? 
				unavail.CurrentEquivalent.HybridId === searchForHybridId : unavail.HybridId === searchForHybridId);
			if (unavailIndex === -1)
			{
				unavailableSeeds.push(_.cloneDeep(seed));
			}
			else if (unavailIndex >= 0)
			{
				unavailableSeeds[unavailIndex].Quantity += seed.Quantity;
			}			
		}
		allSeeds.push(seed);
	});

	useEffect(() =>
	{
		// Reset the traits on switching crop tabs
		setUniqueTraits([]);

		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		if (!IsFieldlessLoading && 
			(currentRecommendationOptions.isFilterApplied) &&
			(!FilteredRankings || (Object.keys(FilteredRankings).length === 0 || !FilteredRankings[cropId + brandApplication])))
		{
			getFilteredRankings();
		}
	},[brandApplication, cropId]);

	useEffect(() =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		if (currentRecommendationOptions && currentRecommendationOptions.isFilterApplied && uniqueTraits && uniqueTraits.length > 0)
		{
			// Set the current applied filter values
			setCurrentAppliedFilter(currentRecommendationOptions);
		}
	}, [uniqueTraits, brandApplication, cropId]);

	useEffect(() =>
	{
		if (hybrids && hybrids.length > 0)
		{
			// Try not to mix Corn and Enogen seeds when flipping between Tabs
			if (brandApplication === 'Any' && hybrids[0].BrandName === 'Enogen')
			{
				return;
			}

			if (hybrids[0].CropId !== cropId)
			{
				setUniqueTraits([]);
				return;
			}

			if (uniqueTraits && uniqueTraits.length === 0)
			{
				// All traits, this will contain duplicates
				const allTraits: ITrait[] = hybrids.map(h =>
				{
					return { TraitName: h.TraitName, TraitId: h.TraitId, Selected: false };
				});

				// Get only the unique traits, we do not want any duplicates
				const newUniqueTraits = _.uniqBy(allTraits, t => t.TraitId);
			
				// TODO: 07/07/2022 - ELM - Change this to use a set of trait names from the client config in the future
				const primaryTraitsNames = ['D', 'V', 'DV']; // the names we want to float to the top of the array
				const primaryTraits: ITrait[] = []; // an array to keep the full ITrait objects for the traits we want to float to the top
				newUniqueTraits.sort(dynamicSort('TraitName')); // sort the unique traits by TraitName

				// Now find the ITrait objects we're looking for and put them into the primary traits array
				newUniqueTraits.forEach(at =>
				{
					if (primaryTraitsNames.includes(at.TraitName))
					{
						primaryTraits.push(at);
					}
				});

				// Filter out the traits so that we don't create duplicates
				let filteredUniqueTraits = newUniqueTraits.filter(at => !primaryTraitsNames.includes(at.TraitName));
				filteredUniqueTraits = filteredUniqueTraits.sort(dynamicSort('TraitName', 'descending'));
			
				// Stick the ITrait objects we found to the front of the sorted array
				filteredUniqueTraits.unshift(...primaryTraits);

				setUniqueTraits(filteredUniqueTraits);
			}

			// Get the Min/Max RM from the hybrid list
			let minRm: number = undefined;
			let maxRm: number = undefined;
			hybrids.forEach((h, index) =>
			{
				const hybridRm = Number.parseFloat(h.RelativeMaturity);
				if (index === 0)
				{
				// If we're on the first element, initialize the min and max to the Rm.
					minRm = hybridRm;
					maxRm = hybridRm;
				}
				if (hybridRm < minRm)
				{
					minRm = hybridRm;
				}
				if (hybridRm > maxRm)
				{
					maxRm = hybridRm;
				}
			});

			setDefaultMinMaxRm([minRm, maxRm]);
			const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
			if (!currentRecommendationOptions.rmMax || !currentRecommendationOptions.rmMin)
			{
				fieldLessRecommendationUpdateRmMaxAndMin([minRm, maxRm]);
			}
			else if (currentRecommendationOptions.rmMax > maxRm)
			{
				if (currentRecommendationOptions.rmMin < minRm)
				{
					fieldLessRecommendationUpdateRmMaxAndMin([minRm, maxRm]);
				}
				else
				{
					fieldLessRecommendationUpdateRmMax(maxRm);
				}
			}
			else if (currentRecommendationOptions.rmMin < minRm)
			{
				fieldLessRecommendationUpdateRmMin(minRm);
			}
		}
	}, [hybrids, recommendation, brandApplication]);

	/**
	 * Retrieves the fieldless recommendations for the selected crop and other settings
	 */
	const fetchFieldlessRecommendations = async () =>
	{	
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];

		// Make sure we supply the configuration settings the user has entered
		const fieldlessRequest: IFieldlessSeedRankingsRequest =
		{
			acres: currentRecommendationOptions.acres,
			consistencyPerf: currentRecommendationOptions.performance,
			cropAssociation: cropId + brandApplication,
			enogenOnly: brandApplication === 'Enogen',
			cropId: cropId,
			fungicide: currentRecommendationOptions.fungicide,
			irrigated: currentRecommendationOptions.irrigated,
			isFilterApplied: currentRecommendationOptions.isFilterApplied,
			rmMax: currentRecommendationOptions.rmMax,
			rmMin: currentRecommendationOptions.rmMin,
			seedsRequested: currentRecommendationOptions.seedsRequired,
			selectedTraits: currentRecommendationOptions.selectedTraits,
			topEndYield: currentRecommendationOptions.topEndYield,
			zipCode: currentRecommendationOptions.zip
		};

		// Fetch the data from the engine
		const response = (await GetFieldlessRecommendations(fieldlessRequest));

		if (response && !response.error)
		{
			const recommendationResults: IFieldlessAllocationResponse = response.payload;

			// Apply this data to the zones
			if (recommendationResults && recommendationResults.Items.length > 0)
			{
				recommendationResults.Items.forEach(rr =>
				{
					const recommendedSeed = Object.values(SeriesLookup).filter(sl =>
						sl.seriesId === rr.SeedWithRank.Id
					);
					if (recommendedSeed && recommendedSeed.length > 0)
					{
						// Add the series
						AddNewSeries(
							{
								customTreatments: CustomTreatments,
								defaultTreatmentName: defaultTreatmentName,
								planId: planId,
								series: SeriesLookup[recommendedSeed[0].seriesId],
								cropId: cropId,
								cropType: cropType,
								appliedArea: Math.round(rr.Acres * 10) / 10,
								yieldTarget: currentRecommendationOptions.targetYield,
								selectedGrower: selectedGrower,
								plantingRates: PlantingRates,
							}
						);
					}
				});
			}
		}
	};

	const fieldLessRecommendationUpdateTargetYield = (value: number) =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		UpdateFieldlessOptions({ 
			cropId: cropId,
			brandAssociation: brandApplication,
			recommendationOptions: { ...currentRecommendationOptions, targetYield: Math.round(value) }
		});
	};

	const fieldLessRecommendationUpdateAcres = (value: number) =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		UpdateFieldlessOptions({ 
			cropId: cropId,
			brandAssociation: brandApplication,
			recommendationOptions: { ...currentRecommendationOptions, acres: value }
		});
	};

	/**
	 * Update the consistency performance value
	 */
	const fieldLessRecommendationUpdatePerformance = (value: number) =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		UpdateFieldlessOptions({ 
			cropId: cropId,
			brandAssociation: brandApplication,
			recommendationOptions: { ...currentRecommendationOptions, performance: value }
		});
	};

	/**
	 * Update the top end yield value
	 */
	const fieldLessRecommendationUpdateTopEndYield = (value: number) =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		UpdateFieldlessOptions({ 
			cropId: cropId,
			brandAssociation: brandApplication,
			recommendationOptions: { ...currentRecommendationOptions, topEndYield: value }
		});
	};

	const fieldLessRecommendationUpdateSeedsRequired = (value: number) =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		UpdateFieldlessOptions({ 
			cropId: cropId,
			brandAssociation: brandApplication,
			recommendationOptions: { ...currentRecommendationOptions, seedsRequired: Math.round(value) }
		});
	};

	const fieldLessRecommendationUpdateIrrigation = () =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		UpdateFieldlessOptions({ 
			cropId: cropId,
			brandAssociation: brandApplication,
			recommendationOptions: { ...currentRecommendationOptions, irrigated: !currentRecommendationOptions.irrigated }
		});
	};

	const fieldLessRecommendationUpdateFungicide = () =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		UpdateFieldlessOptions({ 
			cropId: cropId,
			brandAssociation: brandApplication,
			recommendationOptions: { ...currentRecommendationOptions, fungicide: !currentRecommendationOptions.fungicide }
		});
	};

	const fieldLessRecommendationUpdateZipCode = (value: string, id: string) =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		UpdateFieldlessOptions({ 
			cropId: cropId,
			brandAssociation: brandApplication,
			recommendationOptions: { ...currentRecommendationOptions, zip: value }
		});
	};

	const fieldLessRecommendationUpdateRmMax = (value: number) =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		if (value > defaultMinMaxRm[1])
		{
			value = defaultMinMaxRm[1]; // Do not allow the user to type above the actual max
		}
		UpdateFieldlessOptions({ 
			cropId: cropId,
			brandAssociation: brandApplication,
			recommendationOptions: { ...currentRecommendationOptions, rmMax: value }
		});
	};

	const fieldLessRecommendationUpdateRmMin = (value: number) =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		if (value < defaultMinMaxRm[0])
		{
			value = defaultMinMaxRm[0]; // Do not allow the user to type below the actual min
		}
		UpdateFieldlessOptions({ 
			cropId: cropId,
			brandAssociation: brandApplication,
			recommendationOptions: { ...currentRecommendationOptions, rmMin: value }
		});
	};

	// This function exists specifically for the slider & min/max initialization that can update both values in quick succession that can lead to
	// data being out of date if just using the single functions
	const fieldLessRecommendationUpdateRmMaxAndMin = (value: [number,number]) =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		UpdateFieldlessOptions({ 
			cropId: cropId,
			brandAssociation: brandApplication,
			recommendationOptions: { ...currentRecommendationOptions, rmMax: value[1], rmMin: value[0] }
		});
	};

	const fieldLessRecommendationUpdateTraitsList = (value: string[]) =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		UpdateFieldlessOptions({
			cropId: cropId,
			brandAssociation: brandApplication,
			recommendationOptions: { ...currentRecommendationOptions, selectedTraits: value }
		});
	};

	const callFieldlessRecommendationAndClearExistingSeeds = () =>
	{
		seriesInThisRecommendation.forEach(sir =>
		{
			RemoveSeries({planId: planId, seriesId: sir.seriesId });
		});

		// Call Fieldless Recommendation API
		fetchFieldlessRecommendations();

		setIsShowSeedsModalOpen(false);
	};

	const validateShowSeeds = (): string =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		if (!currentRecommendationOptions.zip || currentRecommendationOptions.zip === '')
		{
			return 'Please enter a zip code to retrieve seeds.';
		}
		else if (currentRecommendationOptions.acres == 0)
		{
			return 'Please add acreage to retrieve seeds.';
		}
		else
		{
			return undefined;
		}
	};

	const validateZipCode = (value: string) =>
	{
		const zipReg = /^([0-9]{5})(?:-([0-9]{4}))?$/;
		const matches = zipReg.test(value);
		return matches;
	};

	const closeAgronomicInputsModal = () =>
	{
		setIsAgronomicInputsModalOpen(false);
	};

	const applySeedRankFilter = () =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		UpdateFieldlessOptions({
			cropId: cropId,
			brandAssociation: brandApplication,
			recommendationOptions: { ...currentRecommendationOptions, isFilterApplied: true }
		});

		// Set the current applied filter values
		setCurrentAppliedFilter({ ...currentRecommendationOptions, isFilterApplied: true });

		// Close the modal
		setIsAgronomicInputsModalOpen(false);

		// Call the api
		getFilteredRankings(true);
	};

	const setCurrentAppliedFilter = (currentRecommendationOptions: IRecommendationOptions) =>
	{
		const currentAppliedFilter = appliedFilters;

		const currentRecommendationAppliedFilters: IAppliedFilter = {
			RM: `RM: ${currentRecommendationOptions.rmMin} - ${currentRecommendationOptions.rmMax}`,
			Irrigation: currentRecommendationOptions.irrigated,
			Fungicide: currentRecommendationOptions.fungicide,
			Traits: undefined,
		};
		const currentTraitIds = currentRecommendationOptions.selectedTraits;
		if (currentTraitIds && currentTraitIds.length > 0)
		{
			if (currentTraitIds.length === uniqueTraits.length)
			{
				currentRecommendationAppliedFilters.Traits = 'Traits: All';
			}
			else
			{
				const traitNames: string[] = [];
				currentTraitIds.forEach(t =>
				{
					const foundTrait = uniqueTraits.find(ut => ut.TraitId === t);
					if (foundTrait)
					{
						traitNames.push(foundTrait.TraitName);
					}
				});
				if (traitNames.length > 0)
				{
					currentRecommendationAppliedFilters.Traits = `Traits: ${traitNames.join(', ')}`;
				}
			}
		}
		currentAppliedFilter[cropId + brandApplication] = currentRecommendationAppliedFilters;
		setAppliedFilters({...currentAppliedFilter});
	};

	const clearAgronomicsFilter = () =>
	{
		const currentRecommendationOptions = editingState.recommendationOptions[cropId + brandApplication];
		UpdateFieldlessOptions({
			cropId: cropId,
			brandAssociation: brandApplication,
			recommendationOptions: {
				...currentRecommendationOptions,
				fungicide: false,
				irrigated: false,
				isFilterApplied: false,
				performance: (brandApplication.indexOf('Enogen') > -1 || cropId.indexOf(soyId) > -1) ? 0 : 5,
				rmMax: defaultMinMaxRm[1],
				rmMin: defaultMinMaxRm[0],
				selectedTraits: [],
				topEndYield: (brandApplication.indexOf('Enogen') > -1 || cropId.indexOf(soyId) > -1) ? 0 : 5,
			}
		});

		// Close the modal
		setIsAgronomicInputsModalOpen(false);

		// Reset Agronomics values to defaults
		resetSeedFilters();
	};

	return <div className='MainEditorVerticalStack' style={{
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		margin: '20px 0px 20px 57px',
		flex: '1 1 auto',
		overflow: 'hidden'
	}}>
		<div className='MainEditorTitle' style={{
			fontFamily: theme.fonts.heading,
			fontSize: theme.fontSizes.large,
			fontWeight: theme.fontWeights.bold,
			color: theme.colors.darkGrey,
			display: 'flex'
		}}>
			{`${recommendation.title} ${AllowPricing ? 'Pricing and ' : ''}Units`}
			{AllowProductPlanRecommendation && <div style={{ marginLeft: 'auto' }}>
				<EditableInputDisplay
					className={'ZipCodeEntry'}
					validationTooltip={'Zip Code is not a valid format.'}
					description={'Recommendation for'}
					inputValue={editingState.recommendationOptions[cropId + brandApplication].zip}
					inputType='number'
					validateInput={validateZipCode}
					saveInput={fieldLessRecommendationUpdateZipCode}
					disableEdit={UserIsGrower}
				/>
			</div>}
		</div>
		{hybridsInThisRecommendation.length > 1 && <ConnectedProductSummaryRow cropId={cropId} hybrids={hybridsInThisRecommendation} />}
		<div className='MainEditorContent' style={{
			maxHeight: 700,
			overflowY: 'auto',
			display: 'flex',
			flexDirection: 'column',
			paddingRight: 10,
			paddingBottom: 20,
		}}>
			{AllowProductPlanRecommendation && <div className='MainEditorRecommendationInputs' style={{
				background: theme.colors.white,
				border: `1px solid ${theme.colors.lightestGrey}`,
				borderRadius: 5,
				padding: 14,
				marginTop: 20,
				marginBottom: 8,
				fontFamily: theme.fonts.base,
				fontSize: theme.fontSizes.normal,
				fontWeight: theme.fontWeights.bold
			}}>
				<div style={{ display: 'flex', flexWrap: 'wrap' }} className='FieldlessRecommendation_MainContainer'>
					<div 
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							//columnGap: isIos ? 15 : '0%'
						}}
						className='FieldlessRecommendation_InnerContainer'
					>
						{!isSoy && <div style={{ display: 'flex', alignItems: 'center' }} className='TargetYield_IncrementInput'>
							<IncrementNumberInput
								interval={5}
								max={CropConfig()[cropId].maxYield}
								min={CropConfig()[cropId].minYield}
								onChange={fieldLessRecommendationUpdateTargetYield}
								value={editingState.recommendationOptions[cropId + brandApplication].targetYield}
								title={'Target Yield'}
							/>
						</div>}
						<div style={{ display: 'flex', alignItems: 'center' }} className='Acres_IncrementInput'>
							<IncrementNumberInput
								interval={5}
								max={99999}
								min={0}
								onChange={fieldLessRecommendationUpdateAcres}
								value={editingState.recommendationOptions[cropId + brandApplication].acres}
								title={'Acres'}
							/>
						</div>
						<div style={{ display: 'flex', alignItems: 'center' }} className='SeedsRequired_IncrementInput'>
							<IncrementNumberInput
								interval={1}
								max={10}
								min={1}
								onChange={fieldLessRecommendationUpdateSeedsRequired}
								value={editingState.recommendationOptions[cropId + brandApplication].seedsRequired}
								title={'Seeds Required'}
							/>
						</div>
						<div style={{display: 'flex', alignItems: 'center', marginTop: 25, marginLeft: 'auto' }}>
							<Tooltip
								title={validateShowSeeds()}
								placement='top'
							>
								<span>
									<Button
										disabled={hybrids.length === 0 || !!validateShowSeeds()}
										className='ShowSeeds_Button'
										style={{ height: 40, width: 200 }}
										variant='outlined'
										onClick={() => seriesInThisRecommendation?.length > 0 ? setIsShowSeedsModalOpen(true) : fetchFieldlessRecommendations()}
									>
										Run Recommendation
									</Button>
								</span>
							</Tooltip>							
						</div>
					</div>
					
				</div>
			</div>}
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<div className='AgronomicInputsButtonSection' style={{ marginTop: 7, marginBottom: 7}}>
					{
						<Button
							disabled={false}
							className='AgronomicInputs_Button'
							style={{ height: 40, minWidth: 160, marginRight: 15 }}
							variant='outlined'
							onClick={() => setIsAgronomicInputsModalOpen(true)}
						>
								Agronomic Inputs
						</Button>
					}
				</div>
				{
					(editingState?.recommendationOptions[cropId + brandApplication]?.isFilterApplied && appliedFilters[cropId + brandApplication]) && 
					<div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-between' }}>
						{
							appliedFilters[cropId + brandApplication].Irrigation &&
							<Pill>
								Irrigation: Yes
							</Pill>
						}
						{
							appliedFilters[cropId + brandApplication].Fungicide &&
							<Pill>
								Fungicide: Yes
							</Pill>
						}
						<Pill>
							{appliedFilters[cropId + brandApplication].RM}
						</Pill>
						{
							appliedFilters[cropId + brandApplication].Traits &&
							<Pill>
								{appliedFilters[cropId + brandApplication].Traits}
							</Pill>
						}

					</div>
				}
			</div>
			<div className='MainEditorSelectedProducts'>
				{seriesInThisRecommendation.map(series => 
				{
					const hybridsForThisSeries = Object.values(editingState.products)
						.filter(products => products.cropId === cropId
							&& products.cropType === cropType
							&& products.seriesId === series.seriesId)
						.map(products => products.product);
					const hasTreatment = cropId === soyId &&
						((Object.keys(Treatments).length && hybridsForThisSeries.length) || CustomTreatments.length);
					const productId = hasTreatment && hybridsForThisSeries[0].HybridId;
					const selectedTreatments = hasTreatment ? Object.keys(editingState.products)
						.filter(key => key.includes(productId) && key.length > productId.length)
						.map(key => editingState.products[key].product.Treatment) : [];

					return <div key={series.seriesId + 'PlanSeries'} className='SelectedSeries' style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'stretch'
					}}>
						<SeriesProductRow
							defaultTreatmentName={defaultTreatmentName}
							seriesTemplate={SeriesLookup[series.seriesId]}
							series={series}
							selectedHybrids={hybridsForThisSeries.filter(h => HybridLookup[h.HybridId] && HybridLookup[h.HybridId].Availability !== 'None')}
							planId={planId}
							cropId={cropId}
							cropType={cropType}
							addNewProduct={AddNewItem}
							removeSeries={RemoveSeries}
							treatments={Treatments[cropId]}
							customTreatments={CustomTreatments}
							firstProduct={HybridLookup[hybridsForThisSeries.find(h => HybridLookup[h.HybridId] && HybridLookup[h.HybridId].Availability !== 'None')?.HybridId]}
							selectedTreatments={selectedTreatments}
						/>
						{hybridsForThisSeries.filter((product) => HybridLookup[product.HybridId])
							.map((product) =>
								<HybridProductRow 
									key={product.Key}
									allowPricing={AllowPricing}
									hybrid={HybridLookup[product.HybridId]}
									onUpdate={OnUpdate}
									onUpdateYield={OnUpdateYield}
									getRates={getRates}
									removeProduct={RemoveItem}
									rate={PlantingRates[series.seriesName]}
									plantingRateError={PlantingRateError}
									yieldError={IsPlantingRateError}
									isRateLoading={IsLoadingPlantingRates}
									planId={planId} 
									cropId={cropId} 
									cropType={cropType} 
									product={product}
									isSingleRow={hybridsForThisSeries.filter((product) => HybridLookup[product.HybridId]).length === 1}
								/>
							)}
					</div>;
				})}
			</div>
		</div>		
		<div
			className='MainEditorPriorYearData'
			style={{
				marginTop: 20,
				borderTop: `2px solid ${theme.colors.lightGrey}`,
				display: 'flex',
				flexDirection: 'column',
				flex: '1 1 auto',
				overflow: 'hidden',
				paddingBottom: 35,
				minHeight: !allSeeds.length ? 'inherit' : 160,
				maxHeight: !allSeeds.length ? 'inherit' : 200,
			}}
		>
			{allSeeds.length ?
				<StyledCollapse
					style={{
						background: 'white',
						fontSize: theme.fontSizes.normal,
						fontWeight: theme.fontWeights.bold,
						height: '100%'
					}}
					bordered={false}
				>
					<StyledCollapsePanel header='Prior Year Order Data' key='PriorYearCollapse'>
						<div className='PriorYearRowsContainer' style={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto', paddingRight: 10 }}>
							{availableSeeds.sort(dynamicSort('RM')).map(seed =>
								<ConnectedOrderHybridRow
									key={seed.Id}
									hybrids={hybrids}
									orderSeed={seed}
									planId={planId}
									cropId={cropId}
									cropType={cropType}
								/>
							)}
							{unavailableSeeds.sort(dynamicSort('RM')).map(seed =>
								<ConnectedOrderHybridRow
									key={seed.Id}
									hybrids={hybrids}
									orderSeed={seed}
									planId={planId}
									cropId={cropId}
									cropType={cropType}
									disabled
								/>
							)}
						</div>
					</StyledCollapsePanel>
				</StyledCollapse>
				:
				<span style={{ fontSize: theme.fontSizes.large, fontWeight: theme.fontWeights.bold }}>No Prior Year Order Data</span>
			}
		</div>
		<StyledModal open={isShowSeedsModalOpen} onCancel={() => setIsShowSeedsModalOpen(false)} closable={true} title={'Show Seeds'}>
			<div>
				<span>
					Starting this process will replace any existing selections or edits for this crop. Would you like to proceed?
				</span>
				<div style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						className='fieldless-modal-cancel-button'
						variant='outlined'
						style={{ marginRight: 15, width: 150 }}
						onClick={() => setIsShowSeedsModalOpen(false)}
					>
						Cancel
					</Button>
					<Button
						style={{ width: 150 }}
						className='fieldless-modal-continue-button'
						variant='dark'
						onClick={callFieldlessRecommendationAndClearExistingSeeds}
					>
						Continue
					</Button>
				</div>
			</div>
		</StyledModal>
		<AgronomicsModal
			applyFilter={applySeedRankFilter}
			clearFilter={clearAgronomicsFilter}
			brandApplication={brandApplication}
			closeModal={closeAgronomicInputsModal}
			cropName={cropName}
			currentRecommendationOptions={editingState.recommendationOptions[cropId + brandApplication]}
			defaultMin={defaultMinMaxRm[0]}
			defaultMax={defaultMinMaxRm[1]}
			isOpen={isAgronomicInputsModalOpen}
			uniqueTraits={uniqueTraits && uniqueTraits.length > 0 ? uniqueTraits : []}
			updateConsistency={fieldLessRecommendationUpdatePerformance}
			updateFungicide={fieldLessRecommendationUpdateFungicide}
			updateIrrigation={fieldLessRecommendationUpdateIrrigation}
			updateMaxAndMinRm={fieldLessRecommendationUpdateRmMaxAndMin}
			updateMaxRm={fieldLessRecommendationUpdateRmMax}
			updateMinRm={fieldLessRecommendationUpdateRmMin}
			updateSelectedTraits={fieldLessRecommendationUpdateTraitsList}
			updateTopEndYield={fieldLessRecommendationUpdateTopEndYield}
		/>
	</div>;
}

export const ConnectedPlanProductEditor = connector(PlanProductEditor);